import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { Navbar, Nav, Container, Button, Modal, Form } from "react-bootstrap";
import style from "./Header.module.css";

import logo from "../assets/images/logo2.png";
import logo2 from "../assets/images/logo.jpg";

import { FaFacebook } from "react-icons/fa6";
import { FaXTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { FaPhoneAlt, FaEnvelope } from "react-icons/fa";

const Header = () => {
  const [showLogoOverlay, setShowLogoOverlay] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [expanded, setExpanded] = useState(false); // State to control navbar collapse

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => {
    setShowModal(false);
    setSubmitted(false);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    setTimeout(() => {
      handleCloseModal();
    }, 2000);
  };

  useEffect(() => {
    // Check if the user has already seen the quote modal
    const hasSeenQuote = localStorage.getItem("hasSeenQuote");

    if (!hasSeenQuote) {
      // If not, show the modal and set a flag in localStorage
      const timer = setTimeout(() => {
        handleShowModal();
        localStorage.setItem("hasSeenQuote", "true"); // Set flag to true
      }, 300000); // 5 minutes in milliseconds

      // Cleanup function to clear the timeout if the component unmounts
      return () => clearTimeout(timer);
    }
  }, []);

  return (
    <>
      <div
        style={{
          backgroundColor: "#800000",
          color: "white",
          padding: "10px 0",
        }}
      >
        <Container className="d-flex justify-content-between align-items-center">
          <div className="d-flex flex-wrap align-items-center">
            {/* Phone */}
            <span className="me-3 d-flex align-items-center">
              <FaPhoneAlt className="me-1" />
              <span>+91-9639235473</span>
            </span>
            {/* Email */}
            <a
              href="mailto:info@tradepoint.com"
              className={`${style.iconLink} d-flex align-items-center`}
            >
              <FaEnvelope className="me-1" />
              <span className="d-none d-md-inline">
                tradepoint2015@gmail.com
              </span>
            </a>
          </div>
          <div className="d-flex align-items-center">
            <a
              href="https://facebook.com"
              target="_blank"
              rel="noopener noreferrer"
              className={style.iconLink}
            >
              <FaFacebook />
            </a>
            <a
              href="https://twitter.com"
              target="_blank"
              rel="noopener noreferrer"
              className={style.iconLink}
            >
              <FaXTwitter />
            </a>
            <a
              href="https://instagram.com"
              target="_blank"
              rel="noopener noreferrer"
              className={style.iconLink}
            >
              <FaInstagram />
            </a>
          </div>
        </Container>
      </div>

      <Navbar
        collapseOnSelect
        expand="lg"
        style={{ backgroundColor: "red" }}
        variant="dark"
        expanded={expanded} // Control the navbar expanded state
      >
        <Container>
          <Navbar.Brand
            href="/"
            onMouseEnter={() => setShowLogoOverlay(true)}
            onMouseLeave={() => setShowLogoOverlay(false)}
            style={{ position: "relative" }}
          >
            <img src={logo} alt="Trade Point Logo" className={style.logo} />
            {showLogoOverlay && (
              <>
                <div
                  style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backdropFilter: "blur(8px)",
                    zIndex: 999,
                  }}
                ></div>
                <div
                  style={{
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "500px",
                    height: "500px",
                    backgroundColor: "rgba(255, 255, 255, 0.9)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "2px solid #800000",
                    zIndex: 1000,
                    borderRadius: "10px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                  }}
                  onMouseEnter={() => setShowLogoOverlay(true)}
                  onMouseLeave={() => setShowLogoOverlay(false)}
                >
                  <img
                    src={logo2}
                    alt="Overlay Logo"
                    style={{ width: "80%" }}
                  />
                </div>
              </>
            )}
          </Navbar.Brand>

          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            onClick={() => setExpanded(expanded ? false : "expanded")}
          />
          <Navbar.Collapse id={style["responsive-navbar-nav"]}>
            <Nav className="me-auto mx-auto">
              {[
                "/",
                "/It-solutions",
                "/Ad-pricing",
                "/About",
                "/Carrer",
                "/Contact",
              ].map((path, index) => (
                <Nav.Link
                  as={Link}
                  to={path}
                  className={style["nav-hover"]}
                  key={index}
                  onClick={() => setExpanded(false)} // Close menu after clicking
                >
                  {path.replace("/", "").replace("-", " ") || "Home"}
                </Nav.Link>
              ))}
            </Nav>
            <Nav>
              {!submitted && (
                <Button
                  variant="danger"
                  style={{
                    backgroundColor: "white",
                    color: "red",
                  }}
                  onClick={handleShowModal}
                >
                  Get Quote
                </Button>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
