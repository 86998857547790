import { JobCard } from "../components/Card";
import { PiHandshakeFill } from "react-icons/pi";

const Cards = () => {
  return (
    <>
      <div className="contact-card-container">
        {/* Background Design */}
        <div className="contact-card-design">
          <div className="contact-card-circles">
            <div className="circle circle-small"></div>
            <div className="circle circle-large"></div>
          </div>
        </div>

        {/* Header Section */}
        <div className="contact-card-header">
          <h2 style={{ fontWeight: "600" }}>Career Opportunities</h2>
        </div>
        <div className="contact-card-quote">
          Explore exciting career opportunities with us and take your
          professional growth to the next level. <br />
          We're hiring passionate individuals in various roles—apply today and
          be part of our dynamic team!
        </div>
      </div>

      {/* <ContactSection /> */}
      <div className="cards-Container">
        <JobCard />
      </div>

      <div className="contact-card-container">
        {/* Office Information */}
        <div className="contact-card-offices">
          {/* Corporate Office */}
          <div className="contact-card-office-item">
            <h3>Corporate Office</h3>
            <p>
              <strong>Trade Point </strong>
              <br />
              Lower Ground Floor,
              <br />
              Vikram Arcade,
              <br />
              Railway Road, Aligarh-202001, India
              <br />
              Landmark: Near Railway Station
            </p>
            <span>+91 - 9639235473</span>
            <a
              href="mailto:tradepoint2015@gmail.com"
              className="contact-card-email"
            >
              Send Email
            </a>
          </div>

          {/* Registered Office */}
          <div className="contact-card-office-item">
            <h5 style={{ padding: "10px" }}>
              Empowering Businesses, Connecting Opportunities, Delivering
              Success
            </h5>
            <p style={{ padding: "0px 10px 5px 10px" }}>
              We are dedicated to empowering businesses. Our mission is to
              provide reliable and innovative solutions. Let us help you achieve
              success and growth.
              <br />
              <br />
              TradePoint helps you grow, connect, and succeed in today's
              competitive marketplace.
              <br />
              Get in touch with our sales representative -
              <a href="/contact" className="contact-card-view-all">
                Contact
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cards;
