import React from "react";
import { FaLinkedin, FaInstagram } from "react-icons/fa";
import { FaEnvelope } from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer-main-section">
      {/* Top Section */}
      <div className="footer-main-section-top">
        <h2 className="footer-main-section-heading">
          Still You Need Our{" "}
          <span className="footer-main-section-highlight">Support</span>?
        </h2>
        <div className="footer-main-section-subscribe">
          <input
            type="email"
            placeholder="Enter your email here"
            className="footer-main-section-input"
          />
          <button className="footer-main-section-button">Subscribe Now</button>
        </div>
      </div>

      {/* Middle Section */}
      <div className="footer-main-section-middle">
        {/* About Us Section */}
        <div className="footer-main-section-about">
          <h3>About Us</h3>
          <p>
            At{" "}
            <span className="footer-main-section-highlight">Trade Point</span>,
            we redefine excellence and innovation across industries. Starting as
            a trusted name in{" "}
            <span className="footer-main-section-highlight">
              DIY, Builders Hardware, Locks, and Bath Accessories
            </span>
            , we have grown into a powerhouse, serving as a key database leader
            in the hardware industry.
          </p>
        </div>

        {/* Quick Links Section */}
        <div className="footer-main-section-links">
          <h3>Quick Links</h3>
          <ul>
            <li><Link to="/privacy-policy">Privacy Policy</Link></li>
            <li><Link to="/terms-and-conditions">Terms and Conditions</Link></li>
            <li><Link to="/refund-and-cancellation">Refund and Cancellation</Link></li>
            <li><Link to="/contact">Contact Us</Link></li>
            <li><Link to="/">Home</Link></li>
          </ul>
        </div>
      </div>

      {/* Bottom Section */}
      <div className="footer-main-section-bottom">
        <div className="footer-bottom-logo">
          <img src="/logo.png" alt="Logo" className="footer-logo-image" />
        </div>
        <p>
          Copyright ©{" "}
          <span style={{ color: "#FF4757" }}>{new Date().getFullYear()}</span>{" "}
          Trade Point. All Rights Reserved.
        </p>
        <div className="footer-main-section-icons">
          <a
            href="https://linkedin.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedin />
          </a>
          <a
            href="https://instagram.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram />
          </a>
          <a
            href="mailto:support@tradepoint.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaEnvelope />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
