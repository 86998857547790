import React from "react";

const About = () => {
  return (
    <div className="about-page-container">
      {/* Hero Section */}
      <div className="about-content-section">
        <div className="about-content-wrapper about-content-reverse">
          <img
            src={require("../assets/images/img-hero.png")}
            alt="About Us"
            className="about-image"
          />
          <div className="about-content-text">
            <h2 className="about-heading">About Us</h2>
            <p>
              At <strong className="imp-word-col">Trade Point</strong>, we
              redefine excellence and innovation across industries. Starting as
              a trusted name in{" "}
              <strong className="imp-word-col">
                DIY, Builders Hardware, Locks, and Bath Accessories
              </strong>
              , we have grown into a powerhouse, serving as a key database
              leader in the hardware industry.
            </p>
            <p>
              But we didn't stop there.{" "}
              <strong className="imp-word-col">Trade Point</strong> has expanded
              its horizons to become a catalyst for digital transformation by:
            </p>
            <ul>
              <li>
                Crafting cutting-edge{" "}
                <strong className="imp-word-col">e-commerce platforms</strong>.
              </li>
              <li>
                Developing smart{" "}
                <strong className="imp-word-col">ERP and CRM solutions</strong>.
              </li>
              <li>
                Delivering dynamic{" "}
                <strong className="imp-word-col">
                  digital marketing strategies
                </strong>{" "}
                for impactful brand presence.
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Content Section */}
      <div className="about-content-section">
        <div className="about-content-wrapper">
          <img
            src={require("../assets/images/about-img.png")}
            alt="Company"
            className="about-image"
          />
          <div className="about-content-text animate-zoom-in">
            <h2 className="about-heading">About Our Company</h2>
            <p>
              But we didn't stop there.{" "}
              <strong className="imp-word-col">Trade Point</strong> has expanded
              its horizons to become a catalyst for digital transformation.
              Here's how we're making waves:
            </p>
            <ul>
              <li>
                Crafting cutting-edge{" "}
                <strong className="imp-word-col">e-commerce platforms</strong>{" "}
                and stunning <strong className="imp-word-col">websites</strong>{" "}
                tailored to your vision.
              </li>
              <li>
                Developing smart{" "}
                <strong className="imp-word-col">ERP and CRM solutions</strong>{" "}
                that drive efficiency and success.
              </li>
              <li>
                Delivering dynamic{" "}
                <strong className="imp-word-col">
                  digital marketing strategies
                </strong>
                , from impactful{" "}
                <strong className="imp-word-col">SEO campaigns</strong> to
                engaging{" "}
                <strong className="imp-word-col">
                  social media management
                </strong>{" "}
                that ensures your brand stands out.
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Aim Section */}
      <div className="about-aim-section">
        <div className="about-content-wrapper about-content-reverse">
          <img
            src={require("../assets/images/img-aim.jpg")}
            alt="Aim of the Company"
            className="about-image"
          />
          <div className="about-content-text animate-slide-in">
            <h2 className="about-heading">Our Aim</h2>
            <p>
              Our mission is to provide innovative and efficient solutions to
              help businesses thrive in a competitive environment. We aim to
              create products that simplify workflows, enhance productivity, and
              deliver outstanding customer satisfaction.
            </p>
            <p>
              Through strategic initiatives and continuous innovation, we
              empower businesses to stay ahead of market trends, enhancing
              growth and market presence.
            </p>
          </div>
        </div>
      </div>

      {/* Benefits Section */}
      <div className="about-benefits-section">
        <h2 className="about-benefits-heading">
          Benefits of Partnering with Trade Point
        </h2>
        <div className="about-content-wrapper ">
          <img
            src={require("../assets/images/benefit-img.png")}
            alt="Benefits of Partnership"
            className="about-image"
          />
          <div className="about-benefits-text">
            <ul>
              <li>
                <strong className="imp-word-col">
                  Boost Productivity and Product Exposure
                </strong>{" "}
                — Showcase your products to a wider audience and streamline
                processes with innovative tools.
              </li>
              <li>
                <strong className="imp-word-col">
                  Expand Your Business Network
                </strong>{" "}
                — Connect with key players in the industry for new business
                opportunities.
              </li>
              <li>
                <strong className="imp-word-col">
                  Discover New Opportunities
                </strong>{" "}
                — Stay ahead with insights into market trends and business
                opportunities.
              </li>
              <li>
                <strong className="imp-word-col">
                  Access Key Industry Insights
                </strong>{" "}
                — Stay informed about domestic market influencers and industry
                trends.
              </li>
              <li>
                <strong className="imp-word-col">Nationwide Reach</strong> —
                Gain exposure through our distribution channels and extensive
                database.
              </li>
              <li>
                <strong className="imp-word-col">
                  Seamless Communication Network
                </strong>{" "}
                — Leverage established channels for consistent market influence.
              </li>
              <li>
                <strong className="imp-word-col">Grow Network</strong> — Know
                more about new Mfrs./Suppliers /Importers /Dealers /Distributors
                /Wholesalers/Retailers.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
