import React, { useState } from "react";
import { FaMapMarkerAlt, FaBriefcase, FaEnvelope } from "react-icons/fa";
import { PiGraduationCapFill } from "react-icons/pi";

const jobListings = [
  {
    title: "Sales Manager",
    experience: "4+ years",
    location: "New Delhi",
    description: [
      "Responsible for leading the sales team and driving revenue growth.",
      "Develop and implement sales strategies to achieve business goals.",
      "Manage client relationships and ensure customer satisfaction.",
      "Work from office.",
      "Only graduates/postgraduates can apply.",
    ],
    education: ["UG: Any Graduate", "PG: Any Postgraduate, Other Post Graduate"],
    contact: {
      phone: "+919639535473",
      email: "tradepoint@gmail.com",
    },
  },
  {
    title: "Telecaller",
    experience: "1+ years",
    location: "New Delhi",
    description: [
      "Handle inbound and outbound calls for the banking process.",
      "Must have basic communication skills to assist customers.",
      "Work from office.",
      "Only graduates/postgraduates can apply.",
    ],
    education: ["UG: Any Graduate", "PG: Any Postgraduate, Other Post Graduate"],
    contact: {
      phone: "+919639535473",
      email: "tradepoint@gmail.com",
    },
  },
  {
    title: "Graphic Designer",
    experience: "2+ years",
    location: "New Delhi",
    description: [
      "Create visually appealing designs for digital and print media.",
      "Proficient in design tools like Adobe Photoshop, Illustrator, and Canva.",
      "Work on branding, marketing materials, and social media creatives.",
      "Work from office.",
      "Only graduates/postgraduates can apply.",
    ],
    education: ["UG: Any Graduate in Design/Arts", "PG: Any Postgraduate, Other Post Graduate"],
    contact: {
      phone: "+919639535473",
      email: "tradepoint@gmail.com",
    },
  },
];

export const JobCard = () => {
  const [selectedJob, setSelectedJob] = useState(null);

  const handleModalToggle = (job) => {
    setSelectedJob(job);
  };

  return (
    <>
      {/* <div className="job-list"> */}
        {jobListings.map((job, index) => (
          <div className="job-card" key={index}>
            {/* Image Section */}
            <div className="image-container">
              <img
                src={"https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?q=80&w=2070&auto=format&fit=crop"}
                alt="job-image"
              />
              <span className="badge-for-more">New</span>
            </div>

            {/* Content Section */}
            <div className="content-container">
              <button className="badge-apartment" onClick={() => handleModalToggle(job)}>
                Apply Here
              </button>
              <h4 className="title">{job.title}</h4>
              <p className="address">
                <FaMapMarkerAlt className="icon" /> Location - {job.location}
              </p>

              {/* Property Details */}
              <div className="details-container">
                <span className="detail-item">
                  <PiGraduationCapFill className="icon" />
                  {job.education[0]}
                </span>
                <span className="detail-item">
                  <PiGraduationCapFill className="icon" />
                  {job.education[1]}
                </span>
              </div>
            </div>
          </div>
        ))}
      {/* </div> */}

      {/* Modal Component */}
      {selectedJob && (
        <div className="job-card-modal-overlay" onClick={() => setSelectedJob(null)}>
          <div className="job-card-modal-container" onClick={(e) => e.stopPropagation()}>
            <button className="job-card-modal-close-button" onClick={() => setSelectedJob(null)}>
              &times;
            </button>
            <div className="job-card-modal-header">
              <FaBriefcase className="icon" /> {selectedJob.title}
            </div>
            <div className="job-card-modal-content">
              <p>
                <strong>Experience:</strong> {selectedJob.experience}
              </p>
              <p>
                <strong>
                  <FaMapMarkerAlt className="icon" /> Location:
                </strong>{" "}
                {selectedJob.location}
              </p>
              <p>
                <strong>Job Description:</strong>
                <ul>
                  {selectedJob.description.map((desc, i) => (
                    <li key={i}>{desc}</li>
                  ))}
                </ul>
              </p>
              <p>
                <strong>Education:</strong>
                <ul>
                  {selectedJob.education.map((edu, i) => (
                    <li key={i}>{edu}</li>
                  ))}
                </ul>
              </p>
            </div>
            <div className="job-card-modal-footer">
              <span>
                <FaEnvelope className="icon" /> Send your resumes:{" "}
              </span>
              <a href={`tel:${selectedJob.contact.phone}`}>{selectedJob.contact.phone}</a>
              <a href={`mailto:${selectedJob.contact.email}`}>{selectedJob.contact.email}</a>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
