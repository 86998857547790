import React from "react";

const AdPackage = () => {
  const buttons = [
    { text: "Architectural Hardware & Glass Fittings" },
    { text: "Aluminium Builders Hardware" },
    { text: "Aldrops" },
    { text: "Aluminium Step Ladders & Railing Fittings" },
    { text: "Auto Hinges, Telescopic Ball Bearings & Tubular Locks" },
    { text: "Bath Accessories & Bathroom Fittings" },
    { text: "Builders Hardware" },
    { text: "Brass Builders Hardware" },
    { text: "Curtain Brackets & Cabinet Fittings" },
    { text: "Door Locks, Door Handles & Door Kits" },
    { text: "Floor Springs & Door Closers" },
    { text: "Locks & Wardrobe Sliding Locks" },
    { text: "Mortice Lock Sets" },
    { text: "Zinc Dia Cast Builders Hardware" },
    { text: "S.S. Builders Hardware" },
    { text: "Screws, Hinges & Tower Bolt" },
    { text: "Silicone Sealent & Laser Marking Machine" },
    { text: "Shutter Locks & Godown Locks" },
    { text: "Pad Locks & Cupboard Locks" },
    { text: "Khunti, Wheel Castors & Hardware Goods" },
    { text: "UPVC Door & Window Fittings" },
    { text: "Kitchen Accessories" },
    { text: "Multipurpose Locks" },
    { text: "Indicator Bolt, Rim & Inter Locks" },
    { text: "Rose Handle & Furniture Locks" },
    { text: "Bed Lifts, Cutlery Inserts & Lift Ups" },
  ];

  return (
    <>
      <div className="package-section-one-container">
        {/* Animated Heading */}
        <div className="package-section-one-heading">
          <h1>Builders Hardware, Locks & Bath Accessories</h1>
          <p>
            Explore a wide range of high-quality products for all your building
            and hardware needs.
          </p>
        </div>

        {/* Cards Section */}
        <div className="package-section-one-button-cards">
          {buttons.map((button, index) => (
            <div
              key={index}
              className="package-section-one-card"
              style={{ "--card-index": index }} // Dynamic index for animation delay
            >
              <div className="package-section-one-card-icon">
                {button.icon} {/* Display the icon */}
              </div>
              <h3>{button.text}</h3>
            </div>
          ))}
        </div>
      </div>

      <div className="package-section-second-container">
        <section className="section-table">
          <h1 className="table-title">
            Print Version Advertisement (Sizes & Tariff)
          </h1>
          <div className="table-container">
            <table className="advertisement-table">
              <thead>
                <tr>
                  <th rowSpan="2" className="product-name-header">
                    Product Name
                  </th>
                  <th rowSpan="2">W x H</th>
                  <th colSpan="4" className="rate-header">
                    (SPL. PACKAGES) After Less 70% (Net Amount)
                  </th>
                </tr>
                <tr>
                  <th>Rate Per Issue</th>
                  <th>3 Month (3 Issue)</th>
                  <th>6 Month (6 Issue)</th>
                  <th>1 Year (12 Issue)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td data-label="Product Name">
                    <strong>General Listing (Data Entry)</strong>
                    <br />
                    Company Name, Products, Address, Phone, Fax, e-mail, Website
                    & <br /> Brand name etc.
                  </td>
                  <td data-label="W x H">
                    Leading Mfrs. & <br /> Suppliers Division of India
                  </td>
                  <td data-label="Rate Per Issue">2,100/-</td>
                  <td data-label="3 Month (3 Issue)">1,890/-</td>
                  <td data-label="6 Month (6 Issue)">3,780/-</td>
                  <td data-label="1 Year (12 Issue)">7,560/-</td>
                </tr>
                <tr>
                  <td data-label="Product Name">
                    Business Card Size Advertisement
                  </td>
                  <td data-label="W x H">3.5” x 2.25”</td>
                  <td data-label="Rate Per Issue">4,000/-</td>
                  <td data-label="3 Month (3 Issue)">3,600/-</td>
                  <td data-label="6 Month (6 Issue)">7,200/-</td>
                  <td data-label="1 Year (12 Issue)">14,400/-</td>
                </tr>
                <tr>
                  <td data-label="Product Name">Qtr. Page</td>
                  <td data-label="W x H">3.5” x 4.75”</td>
                  <td data-label="Rate Per Issue">8,800/-</td>
                  <td data-label="3 Month (3 Issue)">7,920/-</td>
                  <td data-label="6 Month (6 Issue)">15,840/-</td>
                  <td data-label="1 Year (12 Issue)">31,680/-</td>
                </tr>
                <tr>
                  <td data-label="Product Name">1/3 Page (Single Column)</td>
                  <td data-label="W x H">2.7” x 11”</td>
                  <td data-label="Rate Per Issue">10,800/-</td>
                  <td data-label="3 Month (3 Issue)">9,720/-</td>
                  <td data-label="6 Month (6 Issue)">19,440/-</td>
                  <td data-label="1 Year (12 Issue)">38,880/-</td>
                </tr>
                <tr>
                  <td data-label="Product Name">Half Page</td>
                  <td data-label="W x H">7” x 4.75”</td>
                  <td data-label="Rate Per Issue">13,200/-</td>
                  <td data-label="3 Month (3 Issue)">11,880/-</td>
                  <td data-label="6 Month (6 Issue)">23,760/-</td>
                  <td data-label="1 Year (12 Issue)">47,520/-</td>
                </tr>
                <tr>
                  <td data-label="Product Name">Double Column</td>
                  <td data-label="W x H">5.4” x 11”</td>
                  <td data-label="Rate Per Issue">19,400/-</td>
                  <td data-label="3 Month (3 Issue)">17,460/-</td>
                  <td data-label="6 Month (6 Issue)">34,920/-</td>
                  <td data-label="1 Year (12 Issue)">69,840/-</td>
                </tr>
                <tr>
                  <td data-label="Product Name">Full Page</td>
                  <td data-label="W x H">8” x 11”</td>
                  <td data-label="Rate Per Issue">25,500/-</td>
                  <td data-label="3 Month (3 Issue)">22,950/-</td>
                  <td data-label="6 Month (6 Issue)">45,900/-</td>
                  <td data-label="1 Year (12 Issue)">91,800/-</td>
                </tr>
                <tr>
                  <td data-label="Product Name">Back Page</td>
                  <td data-label="W x H">8” x 11”</td>
                  <td data-label="Rate Per Issue">31,600/-</td>
                  <td data-label="3 Month (3 Issue)">28,440/-</td>
                  <td data-label="6 Month (6 Issue)">56,880/-</td>
                  <td data-label="1 Year (12 Issue)">1,13,760/-</td>
                </tr>
                <tr>
                  <td data-label="Product Name">Back Inside Page</td>
                  <td data-label="W x H">8” x 11”</td>
                  <td data-label="Rate Per Issue">28,400/-</td>
                  <td data-label="3 Month (3 Issue)">25,560/-</td>
                  <td data-label="6 Month (6 Issue)">51,120/-</td>
                  <td data-label="1 Year (12 Issue)">1,02,240/-</td>
                </tr>
                <tr>
                  <td data-label="Product Name">Cover Inside Page</td>
                  <td data-label="W x H">8” x 11”</td>
                  <td data-label="Rate Per Issue">30,500/-</td>
                  <td data-label="3 Month (3 Issue)">27,450/-</td>
                  <td data-label="6 Month (6 Issue)">54,900/-</td>
                  <td data-label="1 Year (12 Issue)">1,09,800/-</td>
                </tr>
                <tr>
                  <td data-label="Product Name">Gate Fold Page</td>
                  <td data-label="W x H">15.5” x 11”</td>
                  <td data-label="Rate Per Issue">54,900/-</td>
                  <td data-label="3 Month (3 Issue)">49,410/-</td>
                  <td data-label="6 Month (6 Issue)">98,820/-</td>
                  <td data-label="1 Year (12 Issue)">1,97,640/-</td>
                </tr>
                <tr>
                  <td data-label="Product Name">
                    Title Page Advertisement (Front)
                  </td>
                  <td data-label="W x H">8” x 8.75”</td>
                  <td data-label="Rate Per Issue">33,000/-</td>
                  <td data-label="3 Month (3 Issue)">29,700/-</td>
                  <td data-label="6 Month (6 Issue)">59,400/-</td>
                  <td data-label="1 Year (12 Issue)">1,18,800/-</td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>
      </div>
    </>
  );
};

export default AdPackage;
