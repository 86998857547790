import React from "react";


const PrivacyPolicy = () => {
  return (
    <section className="privacy-policy-section pri-policy-additionally">
      <div className="privacy-policy-header pri-policy-additionally">
        <h1 className="privacy-policy-title pri-policy-additionally">
          Privacy Policy
        </h1>
        <p className="privacy-policy-subtitle pri-policy-additionally">
          Your privacy is important to us. This policy outlines how we collect,
          use, and protect your data.
        </p>
      </div>

      <div className="privacy-policy-content pri-policy-additionally">
        <h2 className="section-title pri-policy-additionally">1. Introduction</h2>
        <p className="policy-text pri-policy-additionally">
          At TradePointIndia, we respect your privacy and are committed to
          protecting your personal data. This privacy policy explains how we
          collect, use, and safeguard the information you provide to us when you
          visit our website, use our services, or engage with our content.
        </p>

        <h2 className="section-title pri-policy-additionally">2. Information We Collect</h2>
        <p className="policy-text pri-policy-additionally">
          We collect personal information such as your name, email address,
          phone number, and payment details when you register for an account, make
          a purchase, or contact us through our forms. Additionally, we may collect
          non-personally identifiable information such as IP addresses, browser type,
          and browsing patterns to improve our services.
        </p>

        <h2 className="section-title pri-policy-additionally">3. How We Use Your Information</h2>
        <p className="policy-text pri-policy-additionally">
          The information we collect is used to enhance your experience, process
          transactions, provide customer support, and send marketing communications.
          We may also use your data for internal analytics, improving our website, and
          compliance with legal obligations.
        </p>

        <h2 className="section-title pri-policy-additionally">4. Data Protection</h2>
        <p className="policy-text pri-policy-additionally">
          We use advanced encryption techniques to protect your data. Access to
          your information is limited to authorized personnel only, and we
          implement strict security measures to prevent unauthorized access. We
          continually update our security practices to adapt to emerging threats.
        </p>

        <h2 className="section-title pri-policy-additionally">5. Cookies</h2>
        <p className="policy-text pri-policy-additionally">
          We use cookies to improve your browsing experience and personalize
          content. By using our website, you consent to our use of cookies in
          accordance with this policy. You can control cookies through your
          browser settings.
        </p>

        <h2 className="section-title pri-policy-additionally">6. Third-Party Services</h2>
        <p className="policy-text pri-policy-additionally">
          We may share your data with trusted third-party services, such as payment
          processors, email service providers, and analytics platforms, to enhance
          our services and ensure smooth transactions. These third parties are bound
          by confidentiality agreements and are prohibited from using your data
          for other purposes.
        </p>

        <h2 className="section-title pri-policy-additionally">7. Data Retention</h2>
        <p className="policy-text pri-policy-additionally">
          We will retain your personal information only for as long as necessary to
          fulfill the purposes outlined in this privacy policy, or as required by law.
          Once your data is no longer needed, we will take appropriate measures to
          securely delete or anonymize it.
        </p>

        <h2 className="section-title pri-policy-additionally">8. Your Rights</h2>
        <p className="policy-text pri-policy-additionally">
          You have the right to access, correct, or delete your personal information
          at any time. If you wish to exercise these rights, please contact us via
          the provided channels. You may also request a copy of the information we
          hold about you.
        </p>

        <h2 className="section-title pri-policy-additionally">9. Changes to This Policy</h2>
        <p className="policy-text pri-policy-additionally">
          We may update this privacy policy from time to time to reflect changes in our
          practices or for other operational, legal, or regulatory reasons. Any changes
          will be posted on this page, and we encourage you to review it periodically.
        </p>

        <h2 className="section-title pri-policy-additionally">10. Legal Obligations</h2>
        <p className="policy-text pri-policy-additionally">
          In certain cases, we may be required by law to disclose your personal information.
          This includes responding to legal requests, complying with court orders, or
          adhering to applicable legal obligations.
        </p>

        <h2 className="section-title pri-policy-additionally">11. Contact Us</h2>
        <p className="policy-text pri-policy-additionally">
          If you have any questions or concerns regarding this privacy policy, please
          contact us at <a href="mailto:support@tradepointindia.com">support@tradepointindia.com</a>.
        </p>
      </div>
    </section>
  );
};

export default PrivacyPolicy;
