import React from "react";
import { useState } from "react";
import { FaDesktop, FaLaptop, FaTabletAlt, FaMobileAlt } from "react-icons/fa";
// import { Card, Button, Row, Col } from "react-bootstrap";
import placeholderImage from "../assets/images/responsive.jpg";

// section 3
import {
  FaProjectDiagram,
  FaSmile,
  FaUserTie,
  FaTrophy,
  FaUsers,
  FaHeadset,
  FaDollarSign,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const ITSolutionsPage = () => {
  const navigate = useNavigate();

  const handleOnClick = (index) => {
    console.log(index);
    switch (index) {
      case 0:
        navigate("/it-solutions/softwareDevelopment");
        break;
      case 1:
        navigate("/it-solutions/webDevelopment");
        break;
      case 2:
        navigate("/it-solutions/appDevelopment");
        break;
      case 3:
        navigate("/it-solutions/eCommerceDevelopment");
        break;
      case 4:
        navigate("/it-solutions/cloudDevelopment");
        break;
      case 5:
        navigate("/it-solutions/digitalDevelopment");
        break;
      case 6:
        navigate("/it-solutions/graphicalDesign");
        break;
      case 7:
        navigate("/it-solutions/shopifyDevelopment");
        break;
    }
  };
  const cardsData = [
    {
      image: require("../assets/images/soft-dev.png"),
      thumb: require("../assets/images/logo.jpg"),
      title: "Software Development",
      status: "Code the future, one line at a time",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores, blanditiis?",
    },
    {
      image: require("../assets/images/web-dev.png"),
      thumb: require("../assets/images/logo.jpg"),
      title: "Web Development",
      status: "Crafting the web, building your vision",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores, blanditiis?",
    },
    {
      image: require("../assets/images/app-dev.png"),
      thumb: require("../assets/images/logo.jpg"),
      title: "App Development",
      status: "Transforming ideas into interactive apps.",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores, blanditiis?",
    },
    {
      image: require("../assets/images/e-commerce-web.png"),
      thumb: require("../assets/images/logo.jpg"),
      title: "Ecommerce Portal",
      status: "Empowering businesses with seamless shopping experiences.",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores, blanditiis?",
    },
    {
      image: require("../assets/images/cloud-comuting.png"),
      thumb: require("../assets/images/logo.jpg"),
      title: "Cloud Computing",
      status: "Scaling heights with the power of the cloud.",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores, blanditiis?",
    },
    {
      image: require("../assets/images/dig-marketing.png"),
      thumb: require("../assets/images/logo.jpg"),
      title: "Digital Marketing",
      status: "Connecting brands to the digital world.",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores, blanditiis?",
    },
    {
      // image: require("../assets/images/graphics-dev.png"),
      thumb: require("../assets/images/logo.jpg"),
      title: "Graphic Designing",
      status: "Connecting brands to the digital world.",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores, blanditiis?",
    },
    {
      // image: require("../assets/images/shopify-dev.png"),
      thumb: require("../assets/images/logo.jpg"),
      title: "Shopify Development",
      status: "Connecting brands to the digital world.",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores, blanditiis?",
    },
  ];

  // section 3
  const stats = [
    { icon: <FaProjectDiagram />, value: "986+", label: "Finished Project" },
    { icon: <FaSmile />, value: "896+", label: "Happy Clients" },
    { icon: <FaUserTie />, value: "396+", label: "Skilled Experts" },
    { icon: <FaTrophy />, value: "496+", label: "Honorable Awards" },
  ];
  const features = [
    {
      icon: <FaUsers />,
      title: "Expert Team Members",
      description:
        "We take pride in assembling a diverse and highly skilled team.",
    },
    {
      icon: <FaHeadset />,
      title: "Fastest Customer Service",
      description: "We provide the fastest customer service in the industry.",
    },
    {
      icon: <FaDollarSign />,
      title: "Reasonable Pricing",
      description:
        "We believe in providing reasonable pricing that offers exceptional value.",
    },
  ];

  // section 3 company details
  const partners = [
    { name: "Radiant", logo: require("../assets/images/logo.jpg") },
    { name: "Maxhub", logo: "/path-to-logo/maxhub.png" },
    { name: "IC Solutions", logo: "/path-to-logo/ic-solutions.png" },
    { name: "Exotec", logo: "/path-to-logo/exotec.png" },
    { name: "Proview", logo: "/path-to-logo/proview.png" },
    { name: "Uniqa", logo: "/path-to-logo/uniqa.png" },
    { name: "Clute", logo: "/path-to-logo/clute.png" },
  ];

  // section 4 tech stack
  const [activeCategory, setActiveCategory] = useState("frontend");
  const [isTransitioning, setIsTransitioning] = useState(false);

  const technologies = {
    frontend: ["HTML", "CSS", "JavaScript", "ReactJS", "AngularJS", "Next.js"],
    backend: ["Node.js", "Express.js", "TypeScript", "Laravel"],
    databases: ["SQL", "MongoDB", "Postgres"],
    // versionControl: ["GitLab"],
    App: ["React Native", "Ionic", "Flutter"],
    libraries: ["Ant Design", "MUI", "Bootstrap", "Redux", "Tailwind CSS"],
  };

  const handleTabClick = (category) => {
    if (activeCategory !== category) {
      setIsTransitioning(true);
      setTimeout(() => {
        setActiveCategory(category);
        setIsTransitioning(false);
      }, 300); // Duration matches the CSS animation
    }
  };

  // section -six approach
  const steps = [
    {
      id: 1,
      title: "Understand and Plan",
      description:
        "We start by understanding your goals, audience, and requirements. Whether it's a website, app, or strategy, we create a clear plan.",
      icon: "💡",
    },
    {
      id: 2,
      title: "Design Your Solution",
      description:
        "We design intuitive interfaces that reflect your brand and ensure a smooth user experience across platforms.",
      icon: "🎨",
    },
    {
      id: 3,
      title: "Develop and Build",
      description:
        "Using modern tech, we build websites, apps, or software with a focus on performance, security, and scalability.",
      icon: "🔧",
    },
    {
      id: 4,
      title: "Promote and Market",
      description:
        "We create campaigns to boost visibility, engage audiences, and drive growth through SEO, social media, and ads.",
      icon: "📢",
    },
    {
      id: 5,
      title: "Test Everything",
      description:
        "We rigorously test for bugs, compatibility, and responsiveness to ensure optimal performance.",
      icon: "✔️",
    },
    {
      id: 6,
      title: "Launch and Go Live",
      description:
        "We deploy your project smoothly, ensuring everything goes live on time and without errors.",
      icon: "🚀",
    },
    {
      id: 7,
      title: "Support and Evolve",
      description:
        "We offer ongoing support, updates, and improvements as your business grows.",
      icon: "🔄",
    },
  ];

  const quote = "Building Tomorrow's Business";
  const quotenext = "with Innovation";
  const author = "- Trade Point";

  return (
    <div className="it-container">
      <div className="section-one">
        <div className="motivational-section">
          <div className="quote-container">
            <div className="quote">
              {quote.split("").map((char, index) => (
                <span
                  className="letter"
                  key={index}
                  style={{ animationDelay: `${index * 0.1}s` }}
                >
                  {char === " " ? "\u00A0" : char}{" "}
                  {/* Replace spaces with non-breaking space */}
                </span>
              ))}
            </div>
            <div className="quote">
              {quotenext.split("").map((chars, index) => (
                <span
                  className="letter"
                  key={index}
                  style={{ animationDelay: `${index * 0.1}s` }}
                >
                  {chars === " " ? "\u00A0" : chars}
                </span>
              ))}
            </div>
            <p className="author">
              {author.split("").map((char, index) => (
                <span
                  className="letter"
                  key={index}
                  style={{ animationDelay: `${(quote.length + index) * 0.1}s` }}
                >
                  {char}
                </span>
              ))}
            </p>
          </div>
        </div>
      </div>
      <div className="section-two">
        <div className="section-it-two">
          <div className="section-two-container">
            {/* Left Section */}
            <div className="screen-layouts-container">
              <h2 className="screen-layouts-title">
                Responsive Screen Layouts
              </h2>
              <div className="screen-layouts-grid">
                <div className="screen-layout-item">
                  <FaDesktop className="screen-layout-icon red" />
                  <p className="screen-layout-text red">1920 x 1080px</p>
                  <span>Full Screen Layout</span>
                </div>
                <div className="screen-layout-item">
                  <FaLaptop className="screen-layout-icon pink" />
                  <p className="screen-layout-text pink">1364 x 768px</p>
                  <span>Notebook Screen Layout</span>
                </div>
                <div className="screen-layout-item">
                  <FaTabletAlt className="screen-layout-icon maroon" />
                  <p className="screen-layout-text maroon">1024 x 768px</p>
                  <span>Tablet Screen Layout</span>
                </div>
                <div className="screen-layout-item">
                  <FaMobileAlt className="screen-layout-icon pink" />
                  <p className="screen-layout-text pink">375 x 667px</p>
                  <span>Mobile Screen Layout</span>
                </div>
              </div>
            </div>

            {/* Right Section */}
            <div className="section-two-right">
              <img
                src={placeholderImage}
                alt="Overview"
                className="section-two-image"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="section-three">
        {/* Section 2: Services */}
        <section className="cards-section">
          <h2 className="cards-screen-title">Our Services</h2>
          <ul className="cards-list">
            {cardsData.map((card, index) => (
              <li key={index} className="cards-list-item">
                <a className="cards-link">
                  <img src={card.image} className="cards-image" alt="" />
                  <div className="cards-overlay">
                    <div className="cards-header">
                      <svg
                        className="cards-arc"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path />
                      </svg>
                      <img className="cards-thumb" src={card.thumb} alt="" />
                      <div className="cards-header-text">
                        <h3 className="cards-title">{card.title}</h3>
                        {card.tagline && (
                          <span className="cards-tagline">{card.tagline}</span>
                        )}
                        <span className="cards-status">{card.status}</span>
                      </div>
                    </div>
                    <p className="cards-description">
                      <button onClick={() => handleOnClick(index)}>
                        Read More
                      </button>
                    </p>
                  </div>
                </a>
              </li>
            ))}
          </ul>
        </section>
      </div>
      <div className="section-four">
        <div className="section-three">
          {/* Stats Sections */}
          <div className="stats-section">
            <div className="stats-container">
              <div className="stat-item">
                <h2 className="stat-value">276K</h2>
                <p className="stat-label">Worldwide Students</p>
              </div>
              <div className="stat-item">
                <h2 className="stat-value">27+</h2>
                <p className="stat-label">Years Experience</p>
              </div>
              <div className="stat-item">
                <h2 className="stat-value">735+</h2>
                <p className="stat-label">Professional Courses</p>
              </div>
              <div className="stat-item">
                <h2 className="stat-value">5K</h2>
                <p className="stat-label">Beautiful Review</p>
              </div>
            </div>
          </div>
          {/* Features Section */}
          <div className="features-container">
            {features.map((feature, index) => (
              <div className="feature-item" key={index}>
                <div className="feature-icon">{feature.icon}</div>
                <h3 className="feature-title">{feature.title}</h3>
                <p className="feature-description">{feature.description}</p>
              </div>
            ))}
          </div>
          {/* Stats Section */}
          <div className="stats-container">
            {stats.map((stat, index) => (
              <div className="stat-item" key={index}>
                <div className="stat-icon">{stat.icon}</div>
                <p className="stat-value">{stat.value}</p>
                <p className="stat-label">{stat.label}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="section-five">
        <section className="section-five">
          <h2 className="section-five__title">Technologies We Excel In 🚀</h2>
          <div className="section-five__categories">
            {Object.entries(technologies).map(([category, techList]) => (
              <div key={category} className="section-five__category">
                <h3 className="section-five__category-title">
                  {category.charAt(0).toUpperCase() + category.slice(1)}
                </h3>
                <div className="section-five__tech-list">
                  {techList.map((tech, index) => (
                    <div
                      key={tech}
                      className="section-five__tech animate"
                      style={{
                        animationDelay: `${index * 0.1}s`,
                      }}
                    >
                      <span>{tech}</span>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <div className="section-five__cta">
            <button>
              Wide Range of Cutting-Edge Technologies and Many More
            </button>
          </div>
        </section>
      </div>
      <div className="section-six">
        <div className="timeline-container">
          <h2 className="timeline-title">Our Process</h2>
          <div className="timeline">
            {steps.map((step, index) => (
              <div
                key={step.id}
                className={`timeline-step ${
                  index % 2 === 0 ? "left" : "right"
                }`}
              >
                <div className="circle-icon">
                  <span className="step-icon">{step.icon}</span>
                </div>
                <div className="step-content">
                  <h3 className="step-title">{`Step ${index + 1}: ${
                    step.title
                  }`}</h3>
                  <p className="step-description">{step.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* company section */}
      <div className="marquee-container">
        <h3 className="marquee-title">Our Featured Partners</h3>
        <div className="marquee">
          <div className="marquee-content">
            {partners.map((partner, index) => (
              <div key={index} className="marquee-item">
                <img
                  src={partner.logo}
                  alt={partner.name}
                  className="partner-logo"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ITSolutionsPage;
