import React from "react";
import { useState, useEffect } from "react";
import mapImage from "../assets/images/map-img.jpg";

const images = [
  // require("../assets/images/img-home-01.jpeg"),
  // require("../assets/images/img-home-02.jpg"),
  // require("../assets/images/img-home-03.jpg"),
  // require("../assets/images/img-home-04.webp"),
];

function Home() {
  const testimonials = [
    {
      text: "Yes, no doubt it is indeed expensive, but there’s a clear reason why it’s the best tax firm in the world.",
      name: "Elona Mosco",
      title: "Head of Community @Goldman",
    },
    {
      text: "Their services are unmatched! The team is professional and incredibly knowledgeable.",
      name: "John Doe",
      title: "CEO @FinancePro",
    },
    {
      text: "Absolutely worth the investment. Their expertise helped our company save millions.",
      name: "Sophia Lee",
      title: "CFO @TechCorp",
    },
    {
      text: "Incredible service and support. They truly care about their clients.",
      name: "Mike Johnson",
      title: "Founder @StartX",
    },
    {
      text: "Highly recommend them! They have the best financial strategies.",
      name: "Anna Smith",
      title: "Manager @Wealthy",
    },
    {
      text: "Their expertise helped our company grow exponentially.",
      name: "David Wilson",
      title: "CTO @InnovateTech",
    },
  ];

  // popular section products
  const products = [
    {
      id: 1,
      name: "Gaming Laptop",
      price: "$1,499",
      image: require("../assets/images/smartPc.jpg"),
      description: "Powerful laptop for gaming and work.",
      details:
        "✅ Intel Core i9, 32GB RAM, RTX 4080 GPU \n✅ 1TB SSD, 240Hz Display \n✅ RGB Keyboard, Advanced Cooling System \n✅ Perfect for high-end gaming & productivity.",
    },
    {
      id: 2,
      name: "Wireless Headphones",
      price: "$299",
      image: require("../assets/images/headphones.jpg"),
      description: "Premium sound with noise cancellation.",
      details:
        "🎧 Active Noise Cancellation (ANC) \n🎧 Bluetooth 5.3, 40-hour battery life \n🎧 Hi-Res audio drivers, deep bass \n🎧 Comfortable & lightweight design.",
    },
    {
      id: 3,
      name: "Smartphone Pro",
      price: "$999",
      image: require("../assets/images/smartphone.jpg"),
      description: "Flagship smartphone with powerful features.",
      details:
        "📱 6.7-inch OLED, 120Hz refresh rate \n📱 Snapdragon 8 Gen 2, 12GB RAM \n📱 108MP Camera, 5000mAh battery \n📱 5G, Fast charging, Premium build.",
    },
    {
      id: 4,
      name: "Smart Watch",
      price: "$199",
      image: require("../assets/images/smartwatch.jpg"),
      description: "Stylish smartwatch with health tracking.",
      details:
        "⌚ AMOLED display, Always-On mode \n⌚ Heart rate & SpO2 monitoring \n⌚ GPS, 7-day battery life \n⌚ Water-resistant, Fitness tracking.",
    },
  ];

  const visibleTestimonials = 3; // Ensures only 3 testimonials appear at a time

  const [startIndex, setStartIndex] = useState(0);
  const totalTestimonials = testimonials.length;

  const nextTestimonial = () => {
    setStartIndex((prevIndex) =>
      prevIndex + visibleTestimonials >= totalTestimonials ? 0 : prevIndex + 1
    );
  };

  const prevTestimonial = () => {
    setStartIndex((prevIndex) =>
      prevIndex === 0 ? totalTestimonials - visibleTestimonials : prevIndex - 1
    );
  };

  // Auto-slide functionality
  useEffect(() => {
    const interval = setInterval(nextTestimonial, 3000); // Slide every 3 seconds

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [startIndex]);

  // slider for left section

  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  useEffect(() => {
    const interval = setInterval(nextSlide, 3000); // Auto-slide every 3 seconds
    return () => clearInterval(interval);
  }, []);

  const [flipped, setFlipped] = useState({});

  const handleFlip = (id) => {
    setFlipped((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  return (
    <div className="home-container">
      <div className="section-one">
        <div className="banner">
          <div className="slideshow-container left-unique-section">
            <button className="prev left-unique-section" onClick={prevSlide}>
              ❮
            </button>
            <div className="slideshow left-unique-section">
              {images.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`Slide ${index + 1}`}
                  className={`slide left-unique-section ${
                    index === currentIndex ? "active" : ""
                  }`}
                />
              ))}
            </div>
            <button className="next left-unique-section" onClick={nextSlide}>
              ❯
            </button>
          </div>
        </div>
        {/* Right Side: Two Boxes */}
        <div className="sidebar">
          {/* First Box */}
          <div className="product-search">
            <p>Building Tomorrow's Business</p>
            <p>Register Now</p>
          </div>

          {/* Second Box */}
          <div className="business-growth">
            <p>Want to grow your business 10X Faster</p>
            <p>#hardwareSpecialist</p>
          </div>
        </div>
      </div>
      <section className="section-map">
        <div className="map-container">
          <img src={mapImage} alt="Map of Rajasthan" className="map-image" />
        </div>
        <div className="content-container-map">
          <div className="content-header">States and Regions</div>
          <div className="content-list">
            <div className="column">
              <ul>
                <li>ANDHRA PRADESH & TELANGANA</li>
                <li>ASSAM</li>
                <li>BIHAR</li>
                <li>CHHATTISGARH</li>
                <li>DELHI & N.C.R.</li>
                <li>GOA</li>
                <li>GUJARAT</li>
                <li>HARYANA</li>
                <li>JHARKHAND</li>
                <li>KARNATAKA</li>
                <li>KERALA</li>
              </ul>
            </div>
            <div className="column">
              <ul>
                <li>M.P.</li>
                <li>MAHARASHTRA</li>
                <li>WEST BENGAL</li>
                <li>ODISHA</li>
                <li>PUNJAB & J.K.</li>
                <li>RAJASTHAN</li>
                <li>TAMIL NADU</li>
                <li>UTTAR PRADESH</li>
                <li>UTTARAKHAND</li>
                <li>NEPAL</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="popular-products">
        <h2 className="section-title">Popular Products</h2>
        <div className="products-grid">
          {products.map((product) => (
            <div
              key={product.id}
              className={`product-card ${flipped[product.id] ? "flipped" : ""}`}
            >
              <div className="product-card-inner">
                {/* Front Side */}
                <div className="product-card-front">
                  <span
                    className="badge-for-product"
                    onClick={() => handleFlip(product.id)}
                  >
                    TradePoint Assured
                  </span>
                  <img
                    src={product.image}
                    alt={product.name}
                    className="product-image"
                  />
                  <h3 className="product-name">{product.name}</h3>
                  <p className="product-description">{product.description}</p>
                  <p className="product-price">{product.price}</p>
                  <button className="buy-button">Buy Now</button>
                </div>

                {/* Back Side */}
                <div
                  className="product-card-back"
                  onMouseLeave={() => handleFlip(product.id)}
                >
                  <h3>More Details</h3>
                  <p>{product.details}</p>
                  <button
                    className="close-button"
                    onClick={() => handleFlip(product.id)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>

      <div className="section-two-home">
        <section className="testimonial-section">
          {/* Header */}
          <div className="testimonial-header-main">
            <h1 className="testimonial-title-main">What Our Clients Say</h1>
            <p className="testimonial-subtitle-main">
              See how we’ve helped businesses grow and succeed.
            </p>
          </div>

          {/* Testimonials Container */}
          <div
            className="testimonials-container"
            onMouseEnter={() => clearInterval(window.autoSlide)}
            onMouseLeave={() =>
              (window.autoSlide = setInterval(nextTestimonial, 4000))
            }
          >
            {/* Left Arrow */}
            <button className="arrow left-arrow" onClick={prevTestimonial}>
              ❮
            </button>

            {/* Testimonial Slider */}
            <div className="testimonial-slider">
              <div
                className="testimonial-wrapper"
                style={{
                  transform: `translateX(-${
                    startIndex * (100 / visibleTestimonials)
                  }%)`,
                }}
              >
                {testimonials.map((testimonial, index) => (
                  <div className="testimonial-card" key={index}>
                    <p className="testimonial-text">“{testimonial.text}”</p>
                    <div className="testimonial-divider"></div>
                    <p className="testimonial-name">{testimonial.name}</p>
                    <p className="testimonial-title">{testimonial.title}</p>
                  </div>
                ))}
              </div>
            </div>

            {/* Right Arrow */}
            <button className="arrow right-arrow" onClick={nextTestimonial}>
              ❯
            </button>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Home;

// quote code
// <div className="quote-container">
//             <div className="quote">
//               {quote.split("").map((char, index) => (
//                 <span
//                   className="letter"
//                   key={index}
//                   style={{ animationDelay: `${index * 0.1}s` }}
//                 >
//                   {char === " " ? "\u00A0" : char}{" "}
//                   {/* Replace spaces with non-breaking space */}
//                 </span>
//               ))}
//             </div>
//             <div className="quote">
//               {quotenext.split("").map((chars, index) => (
//                 <span
//                   className="letter"
//                   key={index}
//                   style={{ animationDelay: `${index * 0.1}s` }}
//                 >
//                   {chars === " " ? "\u00A0" : chars}
//                 </span>
//               ))}
//             </div>
//             <p className="author">
//               {author.split("").map((char, index) => (
//                 <span
//                   className="letter"
//                   key={index}
//                   style={{ animationDelay: `${(quote.length + index) * 0.1}s` }}
//                 >
//                   {char}
//                 </span>
//               ))}
//             </p>
//           </div>
