import React, { useState } from "react";
import { ImLocation } from "react-icons/im";

const ContactDetailSection = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [userName, setUserName] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent default form behavior
    const formData = new FormData(event.target);
    const name = formData.get("name"); // Get the user's name from the form
    setUserName(name);
    setIsSubmitted(true); // Show the modal
    event.target.reset(); // Reset the form

    // Automatically hide the modal after 3 seconds
    setTimeout(() => {
      setIsSubmitted(false);
    }, 3000);
  };

  return (
    <div className="contact-main">
      {/* Hero Section */}
      <div className="contact-hero">
        <h1>Get in touch</h1>
        <p>
          Want to get in touch? We'd love to hear from you. Here's how you can
          reach us...
        </p>
      </div>

      {/* Containers */}
      <div className="contact-info-container">
        {/* Container 1 */}
        <div className="contact-info-box">
          <div className="contact-icon">📞</div>
          <h2 style={{ fontWeight: "bold" }}>Talk to Sales</h2>
          <p>
            Interested in our services? Just pick up the phone to chat with a
            member of our sales team.
          </p>
          <p>
            <strong>Phone:</strong>{" "}
            <a href="tel:+919639235473">+91-9639235473</a>
          </p>
          <p>
            <strong>Email:</strong>{" "}
            <a href="mailto:tradepoint2015@gmail.com">
              tradepoint2015@gmail.com
            </a>
          </p>
        </div>

        {/* Container 2 */}
        <div className="contact-info-box">
          <div className="contact-icon">📍</div>
          <h2 style={{ fontWeight: "bold" }}>Customer Support</h2>
          <p>
            Sometimes you need a little help. Don’t worry, we’re here for you.
          </p>
          <p style={{ color: "#800000" }}>
            <ImLocation />
            Lower Ground Floor, Vikram Arcade, Railway Road, Aligarh-202001,
            India
          </p>
        </div>
      </div>

      {/* Contact Form */}
      <div className="contact-form-container">
        <h2>Send Us a Message</h2>
        <form onSubmit={handleSubmit}>
          <label>
            Name:
            <input
              type="text"
              name="name"
              placeholder="Enter your name"
              required
            />
          </label>
          <label>
            Email:
            <input
              type="email"
              name="email"
              placeholder="Enter your email"
              required
            />
          </label>
          <label>
            Message:
            <textarea
              name="message"
              placeholder="Write your message"
              required
            ></textarea>
          </label>
          <button type="submit">Submit</button>
        </form>
      </div>

      {/* Modal */}
      {isSubmitted && (
        <div className="modal-contact-backdrop">
          <div className="modal-contact">
            <h2>Thank You!</h2>
            <p>Your response has been submitted successfully.</p>
            <p>
              <strong>{userName}</strong>, we appreciate you taking the time to
              contact us!
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactDetailSection;
