export const AppDevelopment = () => {
  return (
    <div className="softwareDev-container">
      <div className="softwareDev-wrapper">
        {/* Header */}
        <header className="softwareDev-header">
          <h1 className="softwareDev-title">App Development</h1>
          <p className="softwareDev-subtitle">
            Designing intuitive, scalable, and efficient mobile applications.
          </p>
        </header>

        {/* Image Section */}
        <div className="softwareDev-images">
          <div className="softwareDev-mainImage">
            <img
              src={require("../assets/images/appdevpage3.jpg")}
              alt="App Development"
            />
            <p className="image-caption">
              "Empowering businesses through mobile technology."
            </p>
          </div>

          <div className="softwareDev-sideImages">
            <div>
              <img
                src={require("../assets/images/appDevpage.jpg")}
                alt="Feature 1"
              />
              <p className="image-caption">
                "Efficient UI/UX for seamless user experience."
              </p>
            </div>

            <div>
              <img
                src={require("../assets/images/appdevpage4.jpg")}
                alt="Feature 1"
              />
              <p className="image-caption">
                "Optimized performance for faster loading."
              </p>
            </div>
          </div>
        </div>

        {/* Content Section */}
        <main className="softwareDev-main">
          <section className="softwareDev-section">
            <h2 className="softwareDev-heading">Overview</h2>
            <p className="softwareDev-text">
              App development focuses on creating seamless and feature-rich
              mobile applications tailored to meet user needs across platforms.
            </p>
          </section>

          <section className="softwareDev-section">
            <h2 className="softwareDev-heading">Key Features</h2>
            <ul className="softwareDev-list">
              <li>Cross-Platform Compatibility</li>
              <li>Optimized Performance</li>
              <li>Mobile-First Design</li>
              <li>Secure Codebase</li>
              <li>Seamless User Experience</li>
            </ul>
          </section>

          <section className="softwareDev-section">
            <h2 className="softwareDev-heading">Proficiency Areas</h2>
            <div className="softwareDev-grid">
              <div className="softwareDev-card">
                <h3>iOS Development</h3>
                <p>Building high-performance applications for Apple devices.</p>
              </div>
              <div className="softwareDev-card">
                <h3>Android Development</h3>
                <p>Expertise in creating Android apps with Kotlin and Java.</p>
              </div>
              <div className="softwareDev-card">
                <h3>React Native</h3>
                <p>Delivering cross-platform apps with a single codebase.</p>
              </div>
              <div className="softwareDev-card">
                <h3>Flutter</h3>
                <p>Creating stunning apps with Google's UI toolkit.</p>
              </div>
            </div>
          </section>
        </main>
      </div>
    </div>
  );
};

export const ShopifyDevelopment = () => {
  return (
    <div className="softwareDev-container">
      <div className="softwareDev-wrapper">
        {/* Header Section */}
        <header className="softwareDev-header">
          <h1 className="softwareDev-title">Shopify Development</h1>
          <p className="softwareDev-subtitle">
            Building customized, high-performing Shopify stores for your
            business success.
          </p>
        </header>
        {/* Image Section */}
        <div className="softwareDev-images">
          <div className="softwareDev-mainImage">
            <img
              src={require("../assets/images/sdev1.jpg")}
              alt="App Development"
            />
            <p className="image-caption">
              "Building high-converting Shopify stores for your brand's
              success."
            </p>
          </div>

          <div className="softwareDev-sideImages">
            <div>
              <img
                src={require("../assets/images/sdev6.jpg")}
                alt="Feature 1"
              />
              <p className="image-caption">
                "Seamless shopping experiences with custom Shopify solutions."
              </p>
            </div>

            <div>
              <img
                src={require("../assets/images/sdev7.jpg")}
                alt="Feature 1"
              />
              <p className="image-caption">
                "Optimized performance for fast and scalable eCommerce."
              </p>
            </div>
          </div>
        </div>

        {/* Main Content */}
        <main className="softwareDev-main">
          {/* Overview Section */}
          <section className="softwareDev-section fadeIn">
            <h2 className="softwareDev-heading">Overview</h2>
            <p className="softwareDev-text">
              Shopify development focuses on creating visually appealing and
              user-friendly online stores. By leveraging Shopify's robust
              platform, businesses can offer a seamless shopping experience to
              their customers while managing operations efficiently.
            </p>
          </section>

          {/* Key Features Section */}
          <section className="softwareDev-section slideUp">
            <h2 className="softwareDev-heading">Key Features</h2>
            <ul className="softwareDev-list">
              <li>Custom Shopify Theme Design</li>
              <li>App Integration and Customization</li>
              <li>Responsive and Mobile-Optimized Stores</li>
              <li>Secure Payment Gateways</li>
              <li>SEO and Performance Optimization</li>
            </ul>
          </section>

          {/* Skills & Proficiency Areas */}
          <section className="softwareDev-section fadeIn">
            <h2 className="softwareDev-heading">Proficiency Areas</h2>
            <div className="softwareDev-grid">
              <div className="softwareDev-card hoverEffect">
                <h3 className="softwareDev-cardTitle">
                  Custom Theme Development
                </h3>
                <p className="softwareDev-cardText">
                  Creating unique, responsive themes tailored to your brand's
                  needs.
                </p>
              </div>
              <div className="softwareDev-card hoverEffect">
                <h3 className="softwareDev-cardTitle">Shopify Apps</h3>
                <p className="softwareDev-cardText">
                  Integrating and customizing apps to extend store
                  functionality.
                </p>
              </div>
              <div className="softwareDev-card hoverEffect">
                <h3 className="softwareDev-cardTitle">Store Optimization</h3>
                <p className="softwareDev-cardText">
                  Enhancing performance, SEO, and customer experience for better
                  conversions.
                </p>
              </div>
              <div className="softwareDev-card hoverEffect">
                <h3 className="softwareDev-cardTitle">
                  Multi-Channel Integration
                </h3>
                <p className="softwareDev-cardText">
                  Connecting Shopify with platforms like Amazon, eBay, and
                  social media.
                </p>
              </div>
            </div>
          </section>
        </main>
      </div>
    </div>
  );
};

export const SoftwareDevelopment = () => {
  return (
    <div className="softwareDev-container">
      <div className="softwareDev-wrapper">
        <header className="softwareDev-header">
          <h1 className="softwareDev-title">Software Development</h1>
          <p className="softwareDev-subtitle">
            Creating innovative, scalable, and efficient software solutions.
          </p>
        </header>

        {/* Image Section */}
        <div className="softwareDev-images">
          <div className="softwareDev-mainImage">
            <img
              src={require("../assets/images/softdev3.jpg")}
              alt="App Development"
            />
            <p className="image-caption">
              "Crafting scalable software solutions for modern businesses."
            </p>
          </div>

          <div className="softwareDev-sideImages">
            <div>
              <img
                src={require("../assets/images/webdev1.jpg")}
                alt="Feature 1"
              />
              <p className="image-caption">
                "Seamless user experiences with robust and intuitive
                development."
              </p>
            </div>

            <div>
              <img
                src={require("../assets/images/webdev3.jpg")}
                alt="Feature 2"
              />
              <p className="image-caption">
                "Optimized performance for efficiency and growth."
              </p>
            </div>
          </div>
        </div>
        <main className="softwareDev-main">
          <section className="softwareDev-section fadeIn">
            <h2 className="softwareDev-heading">Overview</h2>
            <p className="softwareDev-text">
              Software development is a structured process that involves
              designing, coding, testing, and deploying applications to solve
              real-world problems efficiently.
            </p>
          </section>
          <section className="softwareDev-section slideUp">
            <h2 className="softwareDev-heading">Key Features</h2>
            <ul className="softwareDev-list">
              <li>Agile Development Practices</li>
              <li>Scalable and Maintainable Architecture</li>
              <li>Secure and Optimized Codebase</li>
              <li>Continuous Integration and Deployment</li>
              <li>Performance-Driven Development</li>
            </ul>
          </section>
          <section className="softwareDev-section fadeIn">
            <h2 className="softwareDev-heading">Proficiency Areas</h2>
            <div className="softwareDev-grid">
              <div className="softwareDev-card hoverEffect">
                <h3 className="softwareDev-cardTitle">
                  Full-Stack Development
                </h3>
                <p className="softwareDev-cardText">
                  Expertise in frontend & backend technologies such as React,
                  Node.js, and databases.
                </p>
              </div>
              <div className="softwareDev-card hoverEffect">
                <h3 className="softwareDev-cardTitle">Cloud Computing</h3>
                <p className="softwareDev-cardText">
                  Experience with AWS, Azure, and scalable cloud architectures.
                </p>
              </div>
              <div className="softwareDev-card hoverEffect">
                <h3 className="softwareDev-cardTitle">AI & Automation</h3>
                <p className="softwareDev-cardText">
                  Implementing AI models and automation solutions for
                  efficiency.
                </p>
              </div>
              <div className="softwareDev-card hoverEffect">
                <h3 className="softwareDev-cardTitle">Cybersecurity</h3>
                <p className="softwareDev-cardText">
                  Ensuring secure coding practices and system integrity.
                </p>
              </div>
            </div>
          </section>
        </main>
      </div>
    </div>
  );
};

export const WebDevelopment = () => {
  return (
    <div className="softwareDev-container">
      <div className="softwareDev-wrapper">
        <header className="softwareDev-header">
          <h1 className="softwareDev-title">Web Development</h1>
          <p className="softwareDev-subtitle">
            Building responsive, efficient, and visually stunning web
            applications.
          </p>
        </header>
        {/* Image Section */}
        <div className="softwareDev-images">
          <div className="softwareDev-mainImage">
            <img
              src={require("../assets/images/webdev1.jpg")}
              alt="App Development"
            />
            <p className="image-caption">
              "Building responsive and high-performing websites for businesses."
            </p>
          </div>

          <div className="softwareDev-sideImages">
            <div>
              <img
                src={require("../assets/images/webdev3.jpg")}
                alt="Feature 1"
              />
              <p className="image-caption">
                "Seamless user experiences with modern web technologies."
              </p>
            </div>

            <div>
              <img
                src={require("../assets/images/webdev2.jpg")}
                alt="Feature 2"
              />
              <p className="image-caption">
                "Optimized for speed, security, and scalability."
              </p>
            </div>
          </div>
        </div>
        <main className="softwareDev-main">
          <section className="softwareDev-section fadeIn">
            <h2 className="softwareDev-heading">Overview</h2>

            <p className="softwareDev-text">
              Web development involves creating websites and web applications
              that deliver seamless user experiences and meet modern digital
              needs.
            </p>
          </section>
          <section className="softwareDev-section slideUp">
            <h2 className="softwareDev-heading">Key Features</h2>
            <ul className="softwareDev-list">
              <li>Responsive Design</li>
              <li>SEO Optimization</li>
              <li>Custom Web Applications</li>
              <li>Fast and Secure Websites</li>
              <li>Cross-Browser Compatibility</li>
            </ul>
          </section>
          <section className="softwareDev-section fadeIn">
            <h2 className="softwareDev-heading">Proficiency Areas</h2>
            <div className="softwareDev-grid">
              <div className="softwareDev-card hoverEffect">
                <h3 className="softwareDev-cardTitle">Frontend Development</h3>
                <p className="softwareDev-cardText">
                  Expertise in HTML, CSS, JavaScript, React, and modern UI/UX
                  practices.
                </p>
              </div>
              <div className="softwareDev-card hoverEffect">
                <h3 className="softwareDev-cardTitle">Backend Development</h3>
                <p className="softwareDev-cardText">
                  Mastery of Node.js, PHP, Python, and database management
                  systems.
                </p>
              </div>
              <div className="softwareDev-card hoverEffect">
                <h3 className="softwareDev-cardTitle">Web Performance</h3>
                <p className="softwareDev-cardText">
                  Ensuring fast loading times, optimal performance, and
                  scalability.
                </p>
              </div>
              <div className="softwareDev-card hoverEffect">
                <h3 className="softwareDev-cardTitle">Web Security</h3>
                <p className="softwareDev-cardText">
                  Protecting websites against vulnerabilities and ensuring data
                  integrity.
                </p>
              </div>
            </div>
          </section>
        </main>
      </div>
    </div>
  );
};

export const GraphicsDesigning = () => {
  return (
    <div className="softwareDev-container">
      <div className="softwareDev-wrapper">
        {/* Header Section */}
        <header className="softwareDev-header">
          <h1 className="softwareDev-title">Graphics Designing</h1>
          <p className="softwareDev-subtitle">
            Creating visually stunning designs that communicate your brand
            effectively.
          </p>
        </header>

        {/* Image Section */}
        <div className="softwareDev-images">
          <div className="softwareDev-mainImage">
            <img
              src={require("../assets/images/gdev1.jpg")}
              alt="App Development"
            />
            <p className="image-caption">
              "Bringing ideas to life with creative and impactful designs."
            </p>
          </div>

          <div className="softwareDev-sideImages">
            <div>
              <img
                src={require("../assets/images/gdev9.jpg")}
                alt="Feature 1"
              />
              <p className="image-caption">
                "Designs that blend creativity with functionality seamlessly."
              </p>
            </div>

            <div>
              <img
                src={require("../assets/images/sdev9.jpg")}
                alt="Feature 2"
              />
              <p className="image-caption">
                "Eye-catching visuals for strong brand identity and engagement."
              </p>
            </div>
          </div>
        </div>

        {/* Main Content */}
        <main className="softwareDev-main">
          {/* Overview Section */}
          <section className="softwareDev-section fadeIn">
            <h2 className="softwareDev-heading">Overview</h2>

            <p className="softwareDev-text">
              Graphics designing combines creativity and technology to produce
              visual content that communicates ideas and captivates audiences.
              From branding to UI/UX design, it plays a pivotal role in shaping
              a company’s identity.
            </p>
          </section>

          {/* Key Features Section */}
          <section className="softwareDev-section slideUp">
            <h2 className="softwareDev-heading">Key Features</h2>
            <ul className="softwareDev-list">
              <li>Logo and Branding Design</li>
              <li>UI/UX Design</li>
              <li>Social Media Graphics</li>
              <li>Illustrations and Infographics</li>
              <li>Print Media Design</li>
            </ul>
          </section>

          {/* Skills & Proficiency Areas */}
          <section className="softwareDev-section fadeIn">
            <h2 className="softwareDev-heading">Proficiency Areas</h2>
            <div className="softwareDev-grid">
              <div className="softwareDev-card hoverEffect">
                <h3 className="softwareDev-cardTitle">Adobe Suite</h3>
                <p className="softwareDev-cardText">
                  Expertise in Photoshop, Illustrator, and InDesign.
                </p>
              </div>
              <div className="softwareDev-card hoverEffect">
                <h3 className="softwareDev-cardTitle">UI/UX Design</h3>
                <p className="softwareDev-cardText">
                  Designing user-friendly interfaces using tools like Figma and
                  Sketch.
                </p>
              </div>
              <div className="softwareDev-card hoverEffect">
                <h3 className="softwareDev-cardTitle">Brand Identity</h3>
                <p className="softwareDev-cardText">
                  Creating cohesive branding materials, including logos and
                  guidelines.
                </p>
              </div>
              <div className="softwareDev-card hoverEffect">
                <h3 className="softwareDev-cardTitle">Social Media Graphics</h3>
                <p className="softwareDev-cardText">
                  Crafting engaging designs for social media campaigns.
                </p>
              </div>
            </div>
          </section>
        </main>
      </div>
    </div>
  );
};

export const CloudComputing = () => {
  return (
    <div className="softwareDev-container">
      <div className="softwareDev-wrapper">
        {/* Header Section */}
        <header className="softwareDev-header">
          <h1 className="softwareDev-title">Cloud Computing</h1>
          <p className="softwareDev-subtitle">
            Empowering businesses with scalable, secure, and efficient
            cloud-based solutions.
          </p>
        </header>
        {/* Image Section */}
        <div className="softwareDev-images">
          <div className="softwareDev-mainImage">
            <img
              src={require("../assets/images/cloud9.jpg")}
              alt="App Development"
            />
            <p className="image-caption">
              "Scalable and secure cloud solutions for modern businesses."
            </p>
          </div>

          <div className="softwareDev-sideImages">
            <div>
              <img
                src={require("../assets/images/cdev3.jpg")}
                alt="Feature 1"
              />
              <p className="image-caption">
                "Seamless cloud integration for enhanced efficiency and
                flexibility."
              </p>
            </div>

            <div>
              <img
                src={require("../assets/images/cloud8.jpg")}
                alt="Feature 2"
              />
              <p className="image-caption">
                "Optimized performance with reliable and cost-effective cloud
                services."
              </p>
            </div>
          </div>
        </div>

        {/* Main Content */}
        <main className="softwareDev-main">
          {/* Overview Section */}
          <section className="softwareDev-section fadeIn">
            <h2 className="softwareDev-heading">Overview</h2>

            <p className="softwareDev-text">
              Cloud computing provides on-demand access to computing resources,
              enabling businesses to scale efficiently, reduce costs, and
              enhance collaboration. With a focus on security, reliability, and
              performance, cloud computing has become the backbone of modern IT
              infrastructure.
            </p>
          </section>

          {/* Key Features Section */}
          <section className="softwareDev-section slideUp">
            <h2 className="softwareDev-heading">Key Features</h2>
            <ul className="softwareDev-list">
              <li>Scalable and Flexible Infrastructure</li>
              <li>Cost-Effective Resource Management</li>
              <li>Advanced Security Protocols</li>
              <li>Global Accessibility</li>
              <li>Disaster Recovery and Backup</li>
            </ul>
          </section>

          {/* Skills & Proficiency Areas */}
          <section className="softwareDev-section fadeIn">
            <h2 className="softwareDev-heading">Proficiency Areas</h2>
            <div className="softwareDev-grid">
              <div className="softwareDev-card hoverEffect">
                <h3 className="softwareDev-cardTitle">AWS</h3>
                <p className="softwareDev-cardText">
                  Expertise in Amazon Web Services for hosting, storage, and
                  cloud architecture.
                </p>
              </div>
              <div className="softwareDev-card hoverEffect">
                <h3 className="softwareDev-cardTitle">Microsoft Azure</h3>
                <p className="softwareDev-cardText">
                  Building and managing solutions on Microsoft's Azure platform.
                </p>
              </div>
              <div className="softwareDev-card hoverEffect">
                <h3 className="softwareDev-cardTitle">Google Cloud Platform</h3>
                <p className="softwareDev-cardText">
                  Leveraging GCP's infrastructure for high-performance and
                  scalable applications.
                </p>
              </div>
              <div className="softwareDev-card hoverEffect">
                <h3 className="softwareDev-cardTitle">
                  Hybrid Cloud Solutions
                </h3>
                <p className="softwareDev-cardText">
                  Integrating on-premises infrastructure with cloud services for
                  flexibility.
                </p>
              </div>
            </div>
          </section>
        </main>
      </div>
    </div>
  );
};

export const DigitalMarketing = () => {
  return (
    <div className="softwareDev-container">
      <div className="softwareDev-wrapper">
        {/* Header Section */}
        <header className="softwareDev-header">
          <h1 className="softwareDev-title">Digital Marketing</h1>
          <p className="softwareDev-subtitle">
            Driving brand growth with innovative and data-driven marketing
            strategies.
          </p>
        </header>

        {/* Image Section */}
        <div className="softwareDev-images">
          <div className="softwareDev-mainImage">
            <img
              src={require("../assets/images/ddev2.jpg")}
              alt="App Development"
            />
            <p className="image-caption">
              "Boost your brand with data-driven digital marketing strategies."
            </p>
          </div>

          <div className="softwareDev-sideImages">
            <div>
              <img
                src={require("../assets/images/dmark6.jpg")}
                alt="Feature 1"
              />
              <p className="image-caption">
                "Engaging content and SEO for maximum online visibility."
              </p>
            </div>

            <div>
              <img
                src={require("../assets/images/dig-marketing.png")}
                alt="Feature 2"
              />
              <p className="image-caption">
                "Driving growth through targeted and impactful campaigns."
              </p>
            </div>
          </div>
        </div>
        {/* Main Content */}
        <main className="softwareDev-main">
          {/* Overview Section */}
          <section className="softwareDev-section fadeIn">
            <h2 className="softwareDev-heading">Overview</h2>

            <p className="softwareDev-text">
              Digital marketing leverages online platforms to promote brands,
              products, and services. Through targeted strategies and analytics,
              it helps businesses reach their desired audience effectively.
            </p>
          </section>

          {/* Key Features Section */}
          <section className="softwareDev-section slideUp">
            <h2 className="softwareDev-heading">Key Features</h2>
            <ul className="softwareDev-list">
              <li>Search Engine Optimization (SEO)</li>
              <li>Pay-Per-Click Advertising (PPC)</li>
              <li>Social Media Marketing</li>
              <li>Content Marketing</li>
              <li>Analytics and Reporting</li>
            </ul>
          </section>

          {/* Skills & Proficiency Areas */}
          <section className="softwareDev-section fadeIn">
            <h2 className="softwareDev-heading">Proficiency Areas</h2>
            <div className="softwareDev-grid">
              <div className="softwareDev-card hoverEffect">
                <h3 className="softwareDev-cardTitle">SEO</h3>
                <p className="softwareDev-cardText">
                  Improving website visibility and organic search rankings.
                </p>
              </div>
              <div className="softwareDev-card hoverEffect">
                <h3 className="softwareDev-cardTitle">
                  Social Media Marketing
                </h3>
                <p className="softwareDev-cardText">
                  Driving engagement and brand awareness through platforms like
                  Instagram and Facebook.
                </p>
              </div>
              <div className="softwareDev-card hoverEffect">
                <h3 className="softwareDev-cardTitle">Google Ads</h3>
                <p className="softwareDev-cardText">
                  Creating targeted ad campaigns for better ROI.
                </p>
              </div>
              <div className="softwareDev-card hoverEffect">
                <h3 className="softwareDev-cardTitle">Content Marketing</h3>
                <p className="softwareDev-cardText">
                  Crafting high-quality, engaging content to attract audiences.
                </p>
              </div>
            </div>
          </section>
        </main>
      </div>
    </div>
  );
};

export const EcommercePortal = () => {
  return (
    <div className="softwareDev-container">
      <div className="softwareDev-wrapper">
        <header className="softwareDev-header">
          <h1 className="softwareDev-title">E-Commerce Portal</h1>
          <p className="softwareDev-subtitle">
            Building robust platforms for seamless online shopping experiences.
          </p>
        </header>
        {/* Image Section */}
        <div className="softwareDev-images">
          <div className="softwareDev-mainImage">
            <img
              src={require("../assets/images/ecomdev6.jpg")}
              alt="App Development"
            />
            <p className="image-caption">
              "Building seamless and scalable eCommerce platforms for growth."
            </p>
          </div>

          <div className="softwareDev-sideImages">
            <div>
              <img
                src={require("../assets/images/ecom3.jpg")}
                alt="Feature 1"
              />
              <p className="image-caption">
                "Optimized for speed, conversions, and customer engagement."
              </p>
            </div>

            <div>
              <img
                src={require("../assets/images/ecomdev6.jpg")}
                alt="Feature 2"
              />
              <p className="image-caption">
                "Optimized performance for faster loading."
              </p>
            </div>
          </div>
        </div>
        <main className="softwareDev-main">
          <section className="softwareDev-section fadeIn">
            <h2 className="softwareDev-heading">Overview</h2>

            <p className="softwareDev-text">
              E-commerce portal development involves creating scalable platforms
              that facilitate seamless online transactions and exceptional
              customer experiences.
            </p>
          </section>
          <section className="softwareDev-section slideUp">
            <h2 className="softwareDev-heading">Key Features</h2>
            <ul className="softwareDev-list">
              <li>User-Friendly Interface</li>
              <li>Secure Payment Gateways</li>
              <li>Order Management System</li>
              <li>Mobile-Optimized Platforms</li>
              <li>Analytics and Reporting</li>
            </ul>
          </section>
          <section className="softwareDev-section fadeIn">
            <h2 className="softwareDev-heading">Proficiency Areas</h2>
            <div className="softwareDev-grid">
              <div className="softwareDev-card hoverEffect">
                <h3 className="softwareDev-cardTitle">Shopify Development</h3>
                <p className="softwareDev-cardText">
                  Customizing Shopify themes and apps for businesses.
                </p>
              </div>
              <div className="softwareDev-card hoverEffect">
                <h3 className="softwareDev-cardTitle">WooCommerce</h3>
                <p className="softwareDev-cardText">
                  Building WordPress-based e-commerce platforms.
                </p>
              </div>
              <div className="softwareDev-card hoverEffect">
                <h3 className="softwareDev-cardTitle">Magento</h3>
                <p className="softwareDev-cardText">
                  Expertise in developing scalable Magento stores.
                </p>
              </div>
              <div className="softwareDev-card hoverEffect">
                <h3 className="softwareDev-cardTitle">Custom Solutions</h3>
                <p className="softwareDev-cardText">
                  Tailoring e-commerce portals to unique business needs.
                </p>
              </div>
            </div>
          </section>
        </main>
      </div>
    </div>
  );
};
