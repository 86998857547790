import React from "react";


const TermsAndConditions = () => {
  return (
    <section className="privacy-policy-section pri-policy-additionally">
      <div className="privacy-policy-header pri-policy-additionally">
        <h1 className="privacy-policy-title pri-policy-additionally">
          Terms and Conditions
        </h1>
        <p className="privacy-policy-subtitle pri-policy-additionally">
          Please read these Terms and Conditions carefully before using our
          services.
        </p>
      </div>

      <div className="privacy-policy-content pri-policy-additionally">
        <h2 className="section-title pri-policy-additionally">
          1. Introduction
        </h2>
        <p className="policy-text pri-policy-additionally">
          By accessing this webpage, you are agreeing to be bound by these Terms
          and Conditions (“Terms”) in a legally binding agreement between us
          (“Merchant” or “us” or “we” or “our”) and the User (“you” or “your”).
          Please read these Terms carefully before accessing or using the
          Website. If you do not agree to the Terms, you may not access the
          Platform. We reserve the right to update and change the Terms and
          Conditions by posting updates and changes to the Platform. You are
          advised to check the Terms and Conditions from time to time for any
          updates or changes that may impact you. If at any point such
          amendments are not acceptable to you, we advise you to cease using the
          Platform at such time.
        </p>

        <h2 className="section-title pri-policy-additionally">
          2. User Accounts
        </h2>
        <p className="policy-text pri-policy-additionally">
          By creating an account on our website, you agree to provide accurate
          and complete information and to keep this information up-to-date. You
          are responsible for maintaining the confidentiality of your account
          and password and for restricting access to your account.
        </p>

        <h2 className="section-title pri-policy-additionally">
          3. Use of Our Services
        </h2>
        <p className="policy-text pri-policy-additionally">
          By using our services, you agree to use them only for lawful purposes
          and in a manner that does not infringe the rights of, restrict or
          inhibit anyone else's use and enjoyment of the services. Prohibited
          behavior includes harassing or causing distress or inconvenience to
          any other user, transmitting obscene or offensive content, or
          disrupting the normal flow of dialogue within our services.
        </p>

        <h2 className="section-title pri-policy-additionally">
          4. Termination
        </h2>
        <p className="policy-text pri-policy-additionally">
          We reserve the right to terminate or suspend your account and access
          to our services at our sole discretion, without notice, for conduct
          that we believe violates these Terms or is harmful to other users of
          the services or to us.
        </p>

        <h2 className="section-title pri-policy-additionally">
          5. Intellectual Property
        </h2>
        <p className="policy-text pri-policy-additionally">
          Unless otherwise stated, tradepointindia.com and/or its licensors own
          the intellectual property rights for all material on the website. All
          intellectual property rights are reserved. You may access this from
          tradepointindia.com for your own personal use, subject to restrictions
          set in these terms and conditions.
        </p>

        <h2 className="section-title pri-policy-additionally">
          6. Refund and Cancellation
        </h2>
        <p className="policy-text pri-policy-additionally">
          Our refund and cancellation policy is described in detail on our
          Refund and Cancellation Policy page. Please refer to this page for
          more information on how we handle refunds and cancellations.
        </p>

        <h2 className="section-title pri-policy-additionally">
          7. Modifications to Terms
        </h2>
        <p className="policy-text pri-policy-additionally">
          We may revise these terms and conditions from time to time. The most
          current version will always be posted on our website. By continuing to
          access or use our services after revisions become effective, you agree
          to be bound by the revised terms.
        </p>

        <h2 className="section-title pri-policy-additionally">
          8. Third-Party Links
        </h2>
        <p className="policy-text pri-policy-additionally">
          Our services may contain links to third-party websites or services
          that are not owned or controlled by TradePointIndia. We have no
          control over, and assume no responsibility for, the content, privacy
          policies, or practices of any third-party websites or services. You
          acknowledge and agree that TradePointIndia shall not be responsible or
          liable, directly or indirectly, for any damage or loss caused by or in
          connection with the use of or reliance on any such content, goods, or
          services available on or through any such websites or services.
        </p>

        <h2 className="section-title pri-policy-additionally">9. Contact Us</h2>
        <p className="policy-text pri-policy-additionally">
          If you have any questions about these Terms and Conditions, please
          contact us at:
        </p>
        <p className="policy-text pri-policy-additionally">
          Email: support@tradepointindia.com
        </p>
        <p className="policy-text pri-policy-additionally">
          For any support, please email us at support@tradepointindia.com. Our
          team will get back to you within 24 hours.
        </p>
      </div>
    </section>
  );
};

export default TermsAndConditions;
