// RefundAndCancellation.js
import React from "react";


const RefundAndCancellation = () => {
  return (
    <section className="refund-cancellation-section pri-policy-additionally">
      <div className="refund-header">
        <h1>Refund and Cancellation Policy</h1>
        <p>Effective Date: 30 July 2024</p>
      </div>

      <div className="refund-content">
        <h2>1. Introduction</h2>
        <p>
          At console2success, we strive to provide the best educational resources to our users.
        </p>

        <h2>2. Refunds</h2>
        <p>We do not provide any refund in any case.</p>

        <h2>3. Cancellations</h2>
        <p>
          You can cancel your subscription at any time by contacting us at <strong>console2success@gmail.com</strong>.
          Your cancellation will take effect at the end of the current billing period. We don't entertain any Refund & Cancellation.
        </p>

        <h2>4. Contact Us</h2>
        <p>
          If you have any questions about our Refund and Cancellation Policy, please contact us at:
        </p>
        <p>Email: <strong>console2success@gmail.com</strong></p>
        <p>For any support, please mail to <strong>console2success@gmail.com</strong>. Our team will get back to you within 24 hours.</p>
      </div>
    </section>
  );
};

export default RefundAndCancellation;
